/*
 * Base: Perfectha - Themes
 * This is used to apply classes to rows in the grid, which will define a theme for all grid elements in that row.
 * An example of this is to add a blue theme, and then the quote will have a different background.
 */

.theme-branded {
  position: relative;
  padding: var(--gutter-width--mobile);

  @media (--viewport-md-min) {
    padding: var(--gutter-width);
  }

  &:after {
    content: '';
    position: absolute;
    top: var(--gutter-width--mobile);
    left: var(--gutter-width--mobile);
    width: calc(100% - var(--gutter-width--mobile) * 2);
    height: calc(100% - var(--gutter-width--mobile) * 2);
    overflow: hidden;
    border: 6px solid var(--color-black);
    z-index: -1;

    @media (--viewport-md-min) {
      top: var(--gutter-width);
      left: var(--gutter-width);
      width: calc(100% - var(--gutter-width) * 2);
      height: calc(100% - var(--gutter-width) * 2);
    }
  }
}

.theme-perfectha-blue-light {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100.1%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-blue-light);
    z-index: -1;
  }

  .quote {
    &-background {
      &__image {
        fill: currentColor;
      }
    }
  }

  .form {
    & .form-group {
      input,
      select,
      textarea,
      .input {
        background: color(var(--color-white) a(60%));
        border-color: var(--color-white);
      }
    }

    & .button {
      background-color: var(--color-white);
      color: var(--color-text);
    }
  }
}

.theme-perfectha-blue {
  position: relative;
  color: var(--color-white);

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100.1%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-blue);
    z-index: -1;
  }

  &:after {
    border-color: var(--color-white);
  }

  .quote {
    &-background {
      &__image {
        fill: currentColor;
      }
    }
  }

  .form {
    & .form-group {
      input,
      select,
      textarea,
      .input {
        background: color(var(--color-white) a(60%));
        border-color: var(--color-white);
      }
    }

    & .button {
      background-color: var(--color-white);
      color: var(--color-text);
    }
  }
}

.theme-perfectha-orange {
  position: relative;
  color: var(--color-white);

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100.1%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-orange);
    z-index: -1;
  }

  &:after {
    border-color: var(--color-white);
  }

  .quote {
    &-background {
      &__image {
        fill: currentColor;
      }
    }
  }

  .form {
    & .form-group {
      input,
      select,
      textarea,
      .input {
        background: color(var(--color-white) a(60%));
        border-color: var(--color-white);
      }
    }

    & .button {
      background-color: var(--color-white);
      color: var(--color-text);
    }
  }
}

.theme-perfectha-pink {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100.1%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-pink);
    z-index: -1;
  }

  .quote {
    &-background {
      &__image {
        fill: currentColor;
      }
    }
  }

  .form {
    & .form-group {
      input,
      select,
      textarea,
      .input {
        background: color(var(--color-white) a(60%));
        border-color: var(--color-white);
      }
    }

    & .button {
      background-color: var(--color-white);
      color: var(--color-text);
    }
  }
}
