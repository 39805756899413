/**
 * Base: Typography
 */

h1 {
  display: block;
  font-family: 'Hero', 'sans-serif';
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 2.75rem; /* 44px */
  line-height: 2.75rem; /* 44px */
  letter-spacing: 0.125rem; /* 2px */

  @media (--viewport-sm-min) {
    font-size: 4rem; /* 64px */
    line-height: 4rem; /* 64px */
  }
}

h2 {
  display: block;
  font-family: var(--heading-font-family);
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1.75rem; /* 28px */
  line-height: 2.25rem; /* 36px */

  @media (--viewport-sm-min) {
    font-size: 2.1875rem; /* 35px */
    line-height: 2.8125rem; /* 45px */
  }

  .quirk {
    display: table;
    font-style: normal;
    font-weight: var(--heading-font-weight);
  }
}

h3 {
  display: block;
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1.375rem; /* 22px */
  line-height: 1.75rem; /* 28px */

  @media (--viewport-sm-min) {
    font-size: 1.5rem; /* 24px */
    line-height: 1.5rem; /* 24px */
  }

  p + & {
    margin-top: 4rem;
  }
}

h4 {
  display: block;
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.125rem; /* 18px */
  line-height: 1.125rem; /* 18px */
  letter-spacing: 0.3125rem; /* 5px */

  @media (--viewport-sm-min) {
    font-size: 1.125rem; /* 18px */
    line-height: 1.125rem; /* 18px */
  }
}

p {
  margin-top: 0;
  margin-bottom: 24px;

  @media (--viewport-md-min) {
    font-size: 1.125rem; /* 18px */
  }
}

/* Bold */
b,
strong {
  font-weight: 700;
}

/* Small */
small {
  font-size: 0.875rem; /* 14px */
}

/* Large */
.large {
  font-size: 1.375rem; /* 22px */
  line-height: 2.0625rem; /* 33px */
}
