/**
 * Fonts
 */

/* raleway-regular - latin_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('/dist/instalift/fonts/raleway-v18-latin_cyrillic-regular.woff2')
      format('woff2'),
    url('/dist/instalift/fonts/raleway-v18-latin_cyrillic-regular.woff')
      format('woff');
}
/* raleway-300 - latin_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('/dist/instalift/fonts/raleway-v18-latin_cyrillic-300.woff2')
      format('woff2'),
    url('/dist/instalift/fonts/raleway-v18-latin_cyrillic-300.woff')
      format('woff');
}
/* raleway-500 - latin_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('/dist/instalift/fonts/raleway-v18-latin_cyrillic-500.woff2')
      format('woff2'),
    url('/dist/instalift/fonts/raleway-v18-latin_cyrillic-500.woff')
      format('woff');
}
/* raleway-700 - latin_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('/dist/instalift/fonts/raleway-v18-latin_cyrillic-700.woff2')
      format('woff2'),
    url('/dist/instalift/fonts/raleway-v18-latin_cyrillic-700.woff')
      format('woff');
}

@font-face {
  font-family: 'Hero';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('/dist/instalift/fonts/hero-regular-webfont.woff2') format('woff2'),
    url('/dist/instalift/fonts/hero-regular-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Hero';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('/dist/instalift/fonts/hero-bold-webfont.woff2') format('woff2'),
    url('/dist/instalift/fonts/hero-bold-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Hero';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('/dist/instalift/fonts/hero-light-webfont.woff2') format('woff2'),
    url('/dist/instalift/fonts/hero-light-webfont.woff') format('woff');
}
